import React from "react"

 export default function Appointments() {
   return <div>Appointments</div>
 }

// // /* eslint-disable react/prop-types */
// // /* eslint-disable no-underscore-dangle */
// import React, { useEffect, useRef, useState, useContext } from 'react'
// import axios from 'axios'
// import { toast } from 'react-toastify'
// import {
//   Button,
//   Grid,
//   IconButton,
//   ListItem,
//   List,
//   Card,
//   CardActions,
//   CardActionArea,
// } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import VideocamIcon from '@material-ui/icons/Videocam'
// import MicIcon from '@material-ui/icons/Mic'
// import StopIcon from '@material-ui/icons/Stop'
// import VideocamOffIcon from '@material-ui/icons/VideocamOff'
// import MicOffIcon from '@material-ui/icons/MicOff'
// import { navigate } from 'gatsby'
// import useAuthState from '../../stores/auth'
// import {
//   VideoChatContextProvider,
//   VideoChatContext,
// } from '../../context/videoChatContext'
// import socket from '../../utils/socket'
// import useCountdownTimer from '../../hooks/useCountdownTimer'

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   appBar: {
//     backgroundColor: '#2196F3',
//   },
//   title: {
//     flexGrow: 1,
//   },
//   videoContainer: {
//     padding: theme.spacing(2),
//   },
//   controls: {
//     justifyContent: 'center',
//   },
//   button: {
//     margin: theme.spacing(1),
//   },
// }))

// function VideoChat({ appointment }) {
//   const classes = useStyles()
//   const { getUserId } = useAuthState()

//   const doctor = appointment?.doctor || {}

//   const {
//     callAccepted,
//     myVideo,
//     userVideo,
//     callEnded,
//     stream,
//     settings,
//     setSettings,
//     leaveCall,
//     setCallAccepted,
//     setCallEnded,
//   } = useContext(VideoChatContext)

//   const [roomMembers, setRoomMembers] = useState([])

//   const appointmentDate = appointment?.date
//     ? new Date(appointment.date)
//     : new Date()

//   const { remainingTime, formattedRemainingTime } =
//     useCountdownTimer(appointmentDate)

//   const [isDoctorInRoom, setIsDoctorInRoom] = useState(false)
//   const [isUserInRoom, setIsUserInRoom] = useState(false)

//   const roomRef = useRef(null)

//   useEffect(() => {
//     socket.on('userJoined', data => {
//       setRoomMembers(data.members)

//       roomRef.current = data.roomName
//     })

//     socket.on('userLeft', members => {
//       setRoomMembers(members)
//       setCallAccepted(false)
//       setCallEnded(true)
//     })
//     return () => {
//       const currentRoom = roomRef.current
//       if (currentRoom) {
//         socket.emit('leaveRoom', {
//           roomName: currentRoom,
//           userId: getUserId().toString(),
//         })
//       }
//     }
//   }, [])

//   useEffect(() => {
//     setIsDoctorInRoom(
//       roomMembers.some(
//         memberId => memberId.toString() === getUserId().toString(),
//       ),
//     )
//     setIsUserInRoom(
//       roomMembers.some(
//         memberId => memberId.toString() === appointment?.user?._id.toString(),
//       ),
//     )
//   }, [roomMembers])

//   const toggleVideo = () => {
//     setSettings({ ...settings, video: !settings.video })
//   }

//   const toggleAudio = () => {
//     setSettings({ ...settings, audio: !settings.audio })
//   }

//   return (
//     <div>
//       <Grid container style={{ padding: '20px' }}>
//         <Grid item xs={6}>
//           <List>
//             <ListItem>Video chat with {doctor?.fullName}</ListItem>
//             <ListItem>Start time: {formattedRemainingTime}</ListItem>
//           </List>
//         </Grid>
//         <Grid item xs={6}>
//           <Grid container item xs={12} justifyContent="flex-end" spacing={2}>
//             <Grid item>
//               {isUserInRoom ? (
//                 <Button
//                   disableElevation
//                   variant="contained"
//                   style={{ color: 'red' }}
//                   startIcon={<StopIcon />}
//                   onClick={() => {
//                     leaveCall()
//                     navigate(`/doctors/${appointment.doctor._id}`)
//                   }}
//                 >
//                   Leave Call
//                 </Button>
//               ) : (
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   disableElevation
//                   onClick={() =>
//                     socket.emit('joinRoom', {
//                       roomName: appointment._id.toString(),
//                       userId: getUserId().toString(),
//                     })
//                   }
//                 >
//                   Join call
//                 </Button>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>
//         {stream && (
//           <Grid item xs={12} sm={6}>
//             <Card variant="outlined">
//               <CardActionArea>
//                 <video
//                   playsInline
//                   ref={myVideo}
//                   muted
//                   autoPlay
//                   controls
//                   className="video"
//                 />
//               </CardActionArea>
//               <CardActions className={classes.controls}>
//                 <IconButton
//                   color="primary"
//                   className={classes.iconButton}
//                   onClick={toggleVideo}
//                   disabled={!settings.audio}
//                 >
//                   {settings.video ? <VideocamIcon /> : <VideocamOffIcon />}
//                 </IconButton>
//                 <IconButton
//                   color="primary"
//                   className={classes.iconButton}
//                   onClick={toggleAudio}
//                   disabled={!settings.video}
//                 >
//                   {settings.audio ? <MicIcon /> : <MicOffIcon />}
//                 </IconButton>
//               </CardActions>
//             </Card>
//           </Grid>
//         )}
//         {callAccepted && !callEnded && (
//           <Grid item xs={12} sm={6}>
//             <Card variant="outlined">
//               <CardActionArea>
//                 <video
//                   playsInline
//                   muted
//                   ref={userVideo}
//                   autoPlay
//                   controls
//                   className="video"
//                 />
//               </CardActionArea>
//             </Card>
//           </Grid>
//         )}
//       </Grid>

//       <style jsx>{`
//         .video {
//           max-width: 100%;
//           width: 100%;
//         }

//         @media (max-width: 600px) {
//           /* Adjust dimensions for smaller screens */
//           .video {
//             max-width: 100%;
//             width: 100%;
//             height: auto; /* Maintain aspect ratio */
//           }
//         }
//       `}</style>
//     </div>
//   )
// }

// export default function Meeting({ params }) {
//   const { appointmentId } = params
//   const [appointment, setAppointment] = useState({})
//   const [loading, setLoading] = useState(false)

//   const fetchAppointment = async () => {
//     try {
//       setLoading(true)
//       const result = await axios.get(
//         `${process.env.GATSBY_API_URL}/appointments/${appointmentId}`,
//       )

//       setAppointment(result.data)

//       setLoading(false)
//     } catch (err) {
//       console.log(
//         '🚀 ~ file: [appointmentId].js:44 ~ fetchAppointment ~ err:',
//         err,
//       )
//       setLoading(false)
//       const { message } = err.response.data
//       toast.error(message)
//     }
//   }

//   useEffect(() => {
//     fetchAppointment()
//   }, [])

//   return (
//     <VideoChatContextProvider>
//       {!loading && <VideoChat appointment={appointment} />}
//     </VideoChatContextProvider>
//   )
// }
